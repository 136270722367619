.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.App-body {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-body ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
}

.App-body a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.App-body li:hover {
  color: #61dafb;
}

.App-navbar {
  background-color: #00000a;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 1rem;
}

.App-navbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 2rem;
  height: 100%;
}



.App-navbar a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.App-navbar li:hover {
  color: #61dafb;
}

.home-link {
  font-size: 2rem;
}

.home-link:hover {
  color: #61dafb;
}

